<template>
  <div class="article-slot__wrapper" :class="slotStyle">
    <div v-if="isPinned" class="article-slot__pin-section">
      <a-icon
        :icon="ICON.PIN"
        :width="35"
        :height="35"
        class="article-slot__pin-icon"
      />
    </div>

    <slot name="header" v-if="isHeaderVisible">
      <a-article-header
        :category="category"
        :is-sponsored="isSponsored"
        :hide-category="hideCategory"
        :is-session-wrap="isSessionWrap"
        class="article-slot__header"
      />
    </slot>

    <component :is="titleHtmlTag" class="article-slot__title title top">
      <a-link :to="linkToArticle" :trailing-slash="!isMigrated">{{
        title
      }}</a-link>
    </component>
    <div class="article-slot__content-wrapper">
      <div
        v-if="image || featuredVideo"
        class="article-slot__media-wrapper"
        :class="dynamicImageWrapperClasses"
      >
        <a-media
          :image="image"
          :image-aspect-ratio="featuredMediaAspectRatio"
          :image-width="$options.consts.renderImageWidth"
          :video="featuredVideo"
          :video-aspect-ratio="featuredMediaAspectRatio"
          :url="linkToArticle"
          :link-trailing-slash="!isMigrated"
          :viewport-optimization="imageViewportOptimization"
          :srcset-location="imageSrcsetLocation"
        />
      </div>

      <div class="article-slot__content">
        <component :is="titleHtmlTag" class="article-slot__title title bottom">
          <a-link :to="linkToArticle" :trailing-slash="!isMigrated">{{
            title
          }}</a-link>
        </component>

        <a-visibility hide :on="[$breakpoint.mobile]">
          <a-tldr :tldr="tldr" class="article-slot__tldr" />
        </a-visibility>

        <slot name="footer">
          <article-slot-footer
            :article-id="articleId"
            :slug="slug"
            :category="category"
            :author="author"
            :sponsorship-company="sponsorshipCompany"
            :date="publishedOn"
            :socials="socials"
            :hide-publisher="hidePublisher"
            :hide-socials="isContentExpanded"
            :is-migrated="isMigrated"
            @update-comment-count="onUpdateCommentCount"
          />
        </slot>
      </div>
    </div>
    <a-visibility v-if="expandable" hide :on="[$breakpoint.mobile]">
      <article-slot-expanded-content
        ref="expandedContent"
        v-show="expandable"
        :is-expanded="isContentExpanded"
        :article-id="articleId"
        :socials="socials"
        :slug="slug"
        :category="category"
        :is-migrated="isMigrated"
        :comment-count="commentCount"
        @expand="onExpand"
      >
        <template v-slot:top-expanded-content>
          <slot name="top-expanded-content">
            <a-render-html :html="processedExpandedContent" />
          </slot>
        </template>
        <template v-slot:bottom-expanded-content>
          <slot
            name="bottom-expanded-content"
            :is-content-expanded="isContentExpanded"
          />
        </template>
      </article-slot-expanded-content>
    </a-visibility>
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import { aspectRatios } from 'enums/aspectRatios'
import mixins from '@/utils/mixins'
import AIcon, { ICON } from 'shared/AIcon'
import ATldr from 'shared/ATldr'
import AArticleHeader from 'shared/AArticleHeader'
import ArticleSlotFooter from './ArticleSlotFooter'
import ArticleSlotExpandedContent from './ArticleSlotExpandedContent'
import { HTML_TAG } from '@/utils/helpers/processHtml/enums'
import { generateServerCacheKey } from '@/plugins/helper'
import ARenderHtml from 'shared/ARenderHtml'
import {
  checkIfParsedElementIsTerm,
  getTermIdFromParsedTerm
} from '@/utils/helpers/processHtml/processTerms'
import { IMAGE_SIZE, IMAGE_SIZE_VALUE } from 'enums/images'
import { getFirstFocusableDescendant } from '@fmpedia/helpers'
import { hydrateWhenVisible } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'
import { SLOT_STYLE } from './enums'

export default {
  name: 'AArticleSlot',
  serverCacheKey: ({
    modifiedOn,
    articleId,
    category,
    hideCategory,
    isPinned,
    featuredImage,
    thumbnailImage,
    featuredVideo,
    author,
    sponsorshipCompany,
    hidePublisher,
    slotStyle,
    expandable,
    titleHtmlTag
  }) => {
    const categoryModifiedCacheKeyPart = category
      ? `${category.name}-${category.slug}`
      : ''

    const image = thumbnailImage || featuredImage

    const imageModifiedCacheKeyPart = image
      ? `${image.altText}-${image.caption}-${image.title}`
      : ''

    const authorModifiedCacheKeyPart = author
      ? `${author.name}-${author.photoUrl}-${author.slug}`
      : ''

    function generateLinkedCompanyCacheKey({
      name,
      slug,
      categorySlug,
      logoUrl,
      isActive
    }) {
      return `${name}-${slug}-${categorySlug}-${logoUrl}-${isActive}`
    }

    const companyModifiedCacheKeyPart =
      sponsorshipCompany && sponsorshipCompany.linkedCompany
        ? generateLinkedCompanyCacheKey(sponsorshipCompany.linkedCompany)
        : ''

    const otherFieldsCacheKeyPart = [
      hideCategory,
      isPinned,
      hidePublisher,
      slotStyle || '',
      expandable,
      titleHtmlTag,
      featuredVideo?.ExternalId || ''
    ].join('-')

    const resultingServerCacheKey = [
      articleId,
      modifiedOn,
      categoryModifiedCacheKeyPart,
      imageModifiedCacheKeyPart,
      authorModifiedCacheKeyPart,
      companyModifiedCacheKeyPart,
      otherFieldsCacheKeyPart
    ].join('-')

    return generateServerCacheKey(resultingServerCacheKey)
  },
  mixins: [mixins.urlGenerators],
  components: {
    ARenderHtml,
    AIcon,
    ATldr,
    AArticleHeader,
    ArticleSlotFooter,
    ArticleSlotExpandedContent,
    AMedia: hydrateWhenVisible(() => import('shared/AMedia'), {
      props: [
        'image',
        'image-aspect-ratio',
        'image-width',
        'video',
        'video-aspect-ratio',
        'url',
        'link-trailing-slash',
        'viewport-optimization',
        'srcset-location'
      ]
    })
  },
  /**
   * Please update the relevant ARTICLE_SLOT_PROPS enum in ./enums.js
   * if you update the props
   */
  props: {
    /** Article Data **/
    articleId: propValidator([PROP_TYPES.STRING]),
    title: propValidator([PROP_TYPES.STRING]),
    slug: propValidator([PROP_TYPES.STRING]),
    isMigrated: propValidator([PROP_TYPES.BOOLEAN], false, false),
    isSessionWrap: propValidator([PROP_TYPES.BOOLEAN]),
    sponsorshipCompany: propValidator([PROP_TYPES.OBJECT], false),
    category: propValidator([PROP_TYPES.OBJECT], false),
    tldr: propValidator([PROP_TYPES.STRING], false),
    isPinned: propValidator([PROP_TYPES.BOOLEAN], false, false),
    featuredImage: propValidator([PROP_TYPES.OBJECT], false),
    thumbnailImage: propValidator([PROP_TYPES.OBJECT], false),
    featuredVideo: propValidator([PROP_TYPES.OBJECT], false),
    author: propValidator([PROP_TYPES.OBJECT], false),
    expandedContent: propValidator([PROP_TYPES.STRING], false),
    terms: propValidator([PROP_TYPES.ARRAY], false),
    publishedOn: propValidator([PROP_TYPES.STRING]),
    modifiedOn: propValidator([PROP_TYPES.STRING]),
    /** Component options **/
    hideCategory: propValidator([PROP_TYPES.BOOLEAN], false, false),
    imageViewportOptimization: propValidator(
      [PROP_TYPES.BOOLEAN],
      false,
      false
    ),
    imageSrcsetLocation: propValidator([PROP_TYPES.STRING], false, null),
    hidePublisher: propValidator([PROP_TYPES.BOOLEAN], false, false),
    slotStyle: propValidator([PROP_TYPES.STRING], false, SLOT_STYLE.STYLE_1),
    expandable: propValidator([PROP_TYPES.BOOLEAN], false, false),
    titleHtmlTag: propValidator([PROP_TYPES.STRING], false, HTML_TAG.HEADING_H3)
  },
  consts: {
    renderImageWidth: IMAGE_SIZE_VALUE[IMAGE_SIZE.WIDTH_260]
  },
  data() {
    return {
      ICON,
      aspectRatios,
      isContentExpanded: false,
      commentCount: 0,
      processedExpandedContent: null,
      processHtml: null
    }
  },
  computed: {
    isSponsored() {
      return !!this.sponsorshipCompany
    },
    image() {
      return this.thumbnailImage || this.featuredImage
    },
    isHeaderVisible() {
      return this.category || this.isSponsored
    },
    linkToArticle() {
      return this.generateArticlePath({
        categorySlug: this.category.slug,
        slug: this.slug,
        isMigrated: this.isMigrated
      })
    },
    socials() {
      return {
        twitter: {
          title: this.title,
          path: this.linkToArticle,
          ariaLabel: 'Twitter'
        },
        telegram: {
          title: this.title,
          path: this.linkToArticle,
          ariaLabel: 'Telegram'
        }
      }
    },
    featuredImageAspectRatio() {
      if (this.isMigrated) return aspectRatios.noRatio

      return aspectRatios.articleFeaturedImage
    },
    featuredMediaAspectRatio() {
      if (this.image) return this.featuredImageAspectRatio

      return aspectRatios.articleFeaturedVideo
    },
    dynamicImageWrapperClasses() {
      return {
        'article-slot__no-fixed-height': this.isMigrated,
        image: !!this.image
      }
    },
    parsedElementsHandler() {
      const handlerSettings = [
        {
          conditionFn: checkIfParsedElementIsTerm,
          handlerFn: this.handleTermElement
        }
      ]

      return this.$helper.generateParsedDomElementsHandlerDeep(handlerSettings)
    }
  },
  watch: {
    expandedContent: {
      handler() {
        this.processExpandedContent()
      }
    }
  },
  methods: {
    onUpdateCommentCount(commentCount) {
      this.commentCount = commentCount
    },
    async processExpandedContent() {
      if (!this.isContentExpanded || !this.processHtml) return

      this.processedExpandedContent = this.processHtml(
        await this.handleTerms(this.expandedContent)
      )
    },
    async handleTerms(html) {
      try {
        const [{ default: parser }, { default: render }] = await Promise.all([
          import('posthtml-parser'),
          import('posthtml-render')
        ])

        const parsedHtml = parser(html)
        const processedHtml = this.parsedElementsHandler(parsedHtml)

        return render(processedHtml)
      } catch (err) {
        this.$errorHandler(err, this)
        return html
      }
    },
    handleTermElement(el) {
      const termId = getTermIdFromParsedTerm(el)
      const termSlug = this.terms.find(({ id }) => id === termId)?.slug

      if (!termSlug) {
        return el
      }

      el.attrs.href = this.generateTermUrl({ slug: termSlug })
      el.attrs.target = null // remove BO target="_blank" from the link

      return el
    },
    async initProcessHtml() {
      const { processHtml } = await import('@/utils/helpers/processHtml')
      this.processHtml = processHtml
    },
    expandContent() {
      this.isContentExpanded = true
      this.$emit('expand')
    },
    async onExpand() {
      if (!this.processHtml) {
        await this.initProcessHtml()
        this.expandContent()
        await this.processExpandedContent()
      } else {
        this.expandContent()
      }

      const elementToFocus = getFirstFocusableDescendant(
        this.$refs.expandedContent.$el
      )

      if (elementToFocus) {
        elementToFocus.focus()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.article-slot__wrapper {
  width: 100%;
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  overflow: hidden;
  background: $c--white;

  @include mobile {
    padding: 15px;
  }

  .article-slot__pin-icon {
    margin: -20px -20px 10px -20px;
    fill: $c--main;

    @include mobile {
      margin-left: -15px;
      margin-top: -15px;
    }
  }

  .article-slot__header {
    margin-bottom: 10px;
  }

  .article-slot__content-wrapper {
    display: flex;

    @include mobile {
      flex-direction: column;
    }
  }

  .article-slot__title {
    margin-bottom: 10px;

    @include mobile {
      font-size: 19px;
    }
  }

  .article-slot__media-wrapper {
    width: 200px;
    flex-shrink: 0;
    margin-right: 20px;

    @include mobile {
      width: 260px;
      margin: 0 auto 10px;
    }

    &.image {
      height: 150px;

      @include mobile {
        height: 195px;
      }
    }

    &.article-slot__no-fixed-height {
      height: auto;
    }
  }

  .article-slot__tldr {
    margin-bottom: 10px;
  }

  .article-slot__content {
    flex-grow: 1;
  }

  /* Modifications */

  @mixin show-bottom-title {
    .article-slot__title.bottom {
      display: block;
    }
  }

  @mixin hide-bottom-title {
    .article-slot__title.bottom {
      display: none;
    }
  }

  @mixin show-top-title {
    .article-slot__title.top {
      display: block;
    }
  }

  @mixin hide-top-title {
    .article-slot__title.top {
      display: none;
    }
  }

  /* Media query modification maps */

  /* Style-1 */

  &.style-1 {
    @include show-bottom-title;
    @include hide-top-title;

    @include desktop-md {
      @include show-top-title;
      @include hide-bottom-title;
    }
  }

  /* Style-2 */

  &.style-2 {
    @include show-bottom-title;
    @include hide-top-title;
  }
}
</style>
